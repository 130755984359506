import { AbstractEntityStateService } from '@entity-framework/entity-record/state/abstract-entity.state-service';
import { AppClientTypes } from './app-client-types';
import { Injectable } from '@angular/core';
import { AppClientTypesState } from './app-client-types.state';
import { AppClientTypesDataProvider } from './app-client-types.data-provider';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '@logging/logging.service';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter, map } from 'rxjs';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class AppClientTypesStateService extends AbstractEntityStateService<AppClientTypes, AppClientTypesState> {
  private static storeName = 'app-client-types';

  constructor(
    protected dataProvider: AppClientTypesDataProvider,
    protected route: ActivatedRoute,
    protected messaging: OnscreenMessagingService,
    protected logger: LoggingService,
    private activeSecurity: ActiveSecurityContextStateService
  ) {
    super(AppClientTypesStateService.storeName, dataProvider, route, messaging, logger);

    if (
      this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrolls') ||
      this.activeSecurity.hasOrganisationAuthorityTo('ManageIntegrations')
    ) {
      this.fetchEntity();
    }
  }

  isHrEnabled(integrationId: string): boolean {
    if (!this.entity?.appClients) return true;

    return this.entity.appClients.some(
      (ac) => ac.integrationIds.some((id) => id == integrationId) && ac.appClient === 'IntegrationsClientIntelliHr'
    );
  }

  hasHrEnabled$(): Observable<boolean> {
    return this.entity$.pipe(
      untilDestroyed(this),
      filter((r) => r.appClients && r.appClients.length > -1),
      map((appClientTypes) => {
        return appClientTypes.appClients.some((ac) => ac.appClient === 'IntegrationsClientIntelliHr' && ac.integrationIds.length > 0);
      })
    );
  }
}
