import { Injectable } from '@angular/core';
import { AbstractEntityDataProvider } from '@entity-framework/entity-record/entity-data-provider/abstract-entity.data-provider';
import { AppClientTypes } from './app-client-types';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from '@logging/logging.service';

@Injectable({ providedIn: 'root' })
export class AppClientTypesDataProvider extends AbstractEntityDataProvider<AppClientTypes> {
  protected apiRoot: string = `${environment.apiPaths.integrations}/client/types`;

  constructor(
    protected httpClient: HttpClient,
    protected logger: LoggingService
  ) {
    super(httpClient, logger);
  }
}
